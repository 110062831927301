import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 48px 0;
  background-color: #001db2;
  color: #fff;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    gap: 5rem;
  }

  & > div {
    width: 100%;

    & + img {
      width: 150px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 60%;

      & + img {
        width: 300px;
      }
    }
  }

  .title {
    font-family: Death Star;
    font-size: 28px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 42px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 52px;
    }
  }

  .subtitle {
    font-family: Clash Display;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
      margin-bottom: 2rem;
      font-size: 20px;
    }
  }

  .label-block {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #ffffff1f;
    margin-bottom: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
      margin-bottom: 2rem;
    }
  }

  .label {
    font-family: Clash Display;
    font-size: 20px;
    font-weight: 600;
    line-height: 39.36px;
    letter-spacing: 0.01em;
    text-align: left;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 32px;
    }
  }

  .steps {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;

    .step {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      border-radius: 100px;
      background-color: #fff;

      .bullet {
        font-family: Clash Display;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.12em;
        text-align: left;
        background-color: #00bbee;
        border-radius: 100px;
        padding: 12px 24px;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 18px;
        }
      }

      .text {
        font-family: Clash Display;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        color: #000;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 20px;
        }
      }
    }
  }
`

const GetStarted = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className="container">
        <Content>
          <div>
            <div className="title">{t('How To Start Mining')} ?</div>
            <div className="subtitle">{t('Get started easily with Bunny Mining')} 💰</div>
            <div className="label-block">
              <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_9536_6873)">
                  <g filter="url(#filter0_i_9536_6873)">
                    <path
                      d="M27.6026 10.8964C28.6545 10.3884 29.9349 10.8269 30.29 11.9397C31.1711 14.7007 31.238 17.674 30.4578 20.4965C29.4887 24.0024 27.2787 27.0367 24.2391 29.0346C21.1996 31.0326 17.5376 31.8579 13.9348 31.3572C10.3321 30.8564 7.03407 29.0635 4.65464 26.3124C2.27522 23.5612 0.97653 20.0392 1.00032 16.4019C1.02411 12.7646 2.36876 9.25985 4.78397 6.54005C7.19918 3.82025 10.5204 2.07071 14.1294 1.6171C17.0348 1.25191 19.9675 1.74669 22.5726 3.01661C23.6226 3.52846 23.8719 4.85866 23.2176 5.82632C22.5633 6.79398 21.2527 7.02561 20.1759 6.57269C18.4458 5.84495 16.5434 5.57713 14.6569 5.81424C12.0657 6.13993 9.68111 7.39608 7.94701 9.34886C6.21292 11.3016 5.24748 13.818 5.23039 16.4296C5.21331 19.0411 6.14576 21.5699 7.85416 23.5452C9.56256 25.5205 11.9305 26.8077 14.5172 27.1673C17.104 27.5268 19.7332 26.9342 21.9156 25.4997C24.098 24.0652 25.6847 21.8866 26.3805 19.3694C26.8871 17.5368 26.8963 15.6156 26.4256 13.7987C26.1326 12.6679 26.5508 11.4044 27.6026 10.8964Z"
                      fill="#33FF00"
                    />
                  </g>
                  <g filter="url(#filter1_i_9536_6873)">
                    <path
                      d="M7.29463 16.6799C8.76413 17.7537 9.32436 17.9046 10.5538 19.5241C12.5635 22.1742 12.2678 23.2696 12.8636 23.7742C13.4594 24.2788 16.1917 24.6863 18.0547 22.3812C19.9177 20.0761 22.8233 13.7127 26.5493 9.89602C30.2753 6.07931 32.0138 4.97527 33.1298 4.39737C34.2481 3.82163 30.7066 3.94454 23.7415 9.29656C18.8972 13.0184 15.4714 18.1569 15.4714 18.1569C15.4714 18.1569 14.5021 15.3472 12.3412 13.4755C10.1803 11.6017 4.85361 14.8987 7.2924 16.682L7.29463 16.6799Z"
                      fill="#33FF00"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_i_9536_6873"
                    x="1"
                    y="1.5"
                    width="30"
                    height="30.4"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="0.5" />
                    <feGaussianBlur stdDeviation="0.2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9536_6873" />
                  </filter>
                  <filter
                    id="filter1_i_9536_6873"
                    x="4"
                    y="-2.5"
                    width="32.5"
                    height="32.5"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="0.5" dy="1" />
                    <feGaussianBlur stdDeviation="0.25" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="screen" in2="shape" result="effect1_innerShadow_9536_6873" />
                  </filter>
                  <clipPath id="clip0_9536_6873">
                    <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <div className="label">{t('Complete 5 simple steps')}</div>
            </div>
            <div className="steps">
              <div className="step">
                <div className="bullet">{t('Step')} 1</div>
                <div className="text">{t('Enter BNB amount')}</div>
              </div>
              <div className="step">
                <div className="bullet">{t('Step')} 2</div>
                <div className="text">{t("Click 'Hire Miners'")}</div>
              </div>
              <div className="step">
                <div className="bullet">{t('Step')} 3</div>
                <div className="text">{t('Miners find tokens')}</div>
              </div>
              <div className="step">
                <div className="bullet">{t('Step')} 4</div>
                <div className="text">{t('Compound daily')}</div>
              </div>
              <div className="step">
                <div className="bullet">{t('Step')} 5</div>
                <div className="text">{t('Withdraw profit!')}</div>
              </div>
            </div>
          </div>
          <img src="/images/mining/book.png" alt="book" />
        </Content>
      </div>
    </Wrapper>
  )
}

export default GetStarted
